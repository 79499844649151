.App {
  text-align: center;
  max-width: 73rem;
  margin: 0 auto;
  padding-top: 5rem;
}

.App > div.simulate-text {
  display: flex;
  align-items: center;
  max-width: 95rem;
  margin: 0 auto;
  justify-content: center;
}


.App > h3 {
    color: #AEAEAE;
    font-size: 22px;
    font-weight: 400;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 1.5em;
    letter-spacing: 0px;
    max-width: max(250px, 40rem);
    margin: 0 auto 30px auto;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

footer {
    position: absolute;
    padding: 10px 0;
    text-transform: uppercase;
    bottom: 0;
    color: white;
    font-weight: 700;
    background-color: var(--main-color);
    width: 100%;
    display: flex;
    justify-content: center;
}

h1 {
    font-size: 3.8rem;
    line-height: 1em;
    margin: 25px 0 45px 0;
    letter-spacing: 0px;
    color: var(--main-color);
}

h1 strong {
    color: var(--secondary-text)
}


.explanation {
    width: 25rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.40);
    border-radius: 12px;
    padding: 10px;
    font-size: 13px;
}


.calc {
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    padding: 20px;
    display: flex;
    width: 30rem;
    margin: 50px 30px;
    flex-direction: column;
}

.input-container > label {
    text-align: left;
}
.input-container > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.input-container small {
    margin-left: 10px;
}

input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 15px;
    font-size: 1rem;
    border-radius: 12px;
}

input:focus {
    outline-color: var(--main-color);
}

button {
    border: none;
    background-color: var(--main-color);
    border-radius: 12px;
    color: white;
    font-weight: 600;
    font-size: 1rem;
    width: fit-content;
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.3s background-color;
}

button:hover {
    background-color: rgb(35, 84, 143);
}

.simulate-text {
    display: flex;
    flex-direction: column;
    color: var(--secondary-text);
}

.simulate-text hr {
    margin: 20px;
    background-color: var(--secondary-text);
    width: 60%;
}



.advantages {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    color: var(--text);
    flex-flow: row nowrap;
    text-align: center;
}


.advantages h2 {
    font-size: 5rem;
}

.advantages > div > div { 
    margin-bottom: 20px;
    border-radius: 16px;
    opacity: 0.80;
    padding: 3rem;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 7px 6px 1px rgba(0, 0, 0, 0.14);
}


.row-calc {
    margin: 55px 0;
}

.row-calc img {
    width: 500px;
}

.row-calc > form {
    display: flex;
    flex-wrap: nowrap;
}

img {
    width: 600px;
}

.result-container p {
    color: var(--secondary-text);
    font-size: 1.5rem;
    margin-left: 10px;
}


.success {
    color: var(--text-success) !important;
}

.error {
    color: var(--text-error) !important;
}
.result-container br {
    margin-bottom: 10px;
}

.logo {
    width: 170px;
}

@media only screen and (max-width: 600px) {
    .hidden-mobile {
        display: none;
    }

    .logo {
        width: 130px;
    }
    hr {
        margin: 3px 0 !important;
    }
    .App {
        padding-top: 2rem;
    }
    .App > h1 {
        font-size: 23px;
        margin-bottom: 30px;
    }
    .calc {
        margin: 10px 0 30px;
        width: 100%;
    }

    input {
        max-width: 70%;
    }

    .calc-container {
        max-width: 100%;
    }
}
