:root {
  --main-color: #0095DA;
  --secondary-text: rgb(50, 74, 109);
  --text: #324A6D;
  --text-error: rgb(245, 76, 64);
  --text-success: rgb(133, 191, 117);
}
* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  margin: 0;
}

html {
    min-height: 100vh;
    position: relative;
    padding-bottom: 100px;
}